<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12 md:col-7">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Nama</label>
              <InputText
                v-model="form.nama"
                :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.nama.$invalid && submitted) ||
                  v$.form.nama.$pending.$response
                "
                class="p-error"
                >{{ v$.form.nama.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>No KTP</label>
              <InputMask 
                v-model="form.no_ktp"
                mask="9999999999999999"
                :class="{ 'p-invalid': v$.form.no_ktp.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.no_ktp.$invalid && submitted) ||
                  v$.form.no_ktp.$pending.$response
                "
                class="p-error"
                >{{ v$.form.no_ktp.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>No KK</label>
              <InputText
                v-model="form.no_kk"
                :class="{ 'p-invalid': v$.form.no_kk.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.no_kk.$invalid && submitted) ||
                  v$.form.no_kk.$pending.$response
                "
                class="p-error"
                >{{ v$.form.no_kk.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>Tanggal Lahir</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-calendar"></i
                ></span>
                <Calendar
                  v-model="form.tanggal_lahir"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <small
                v-if="
                  (v$.form.tanggal_lahir.$invalid && submitted) ||
                  v$.form.tanggal_lahir.$pending.$response
                "
                class="p-error"
                >{{ v$.form.tanggal_lahir.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>Tempat Lahir</label>
              <InputText
                v-model="form.tempat_lahir"
                :class="{
                  'p-invalid': v$.form.tempat_lahir.$invalid && submitted,
                }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.tempat_lahir.$invalid && submitted) ||
                  v$.form.tempat_lahir.$pending.$response
                "
                class="p-error"
                >{{ v$.form.tempat_lahir.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>Jenis Kelamin</label>
              <Dropdown
                v-model="form.jenis_kelamin"
                optionValue="key"
                optionLabel="label"
                :class="{
                  'p-invalid': v$.form.jenis_kelamin.$invalid && submitted,
                }"
                :options="[
                  { key: 'laki-laki', label: 'Laki-Laki' },
                  { key: 'Perempuan', label: 'Perempuan' },
                ]"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.jenis_kelamin.$invalid && submitted) ||
                  v$.form.jenis_kelamin.$pending.$response
                "
                class="p-error"
                >{{ v$.form.jenis_kelamin.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>Agama</label>
              <Dropdown
                v-model="form.agama"
                optionValue="key"
                optionLabel="label"
                :class="{
                  'p-invalid': v$.form.agama.$invalid && submitted,
                }"
                :options="[
                  { key: 'Islam', label: 'Islam' },
                  { key: 'Kristen', label: 'Kristen' },
                  { key: 'Khatolik', label: 'Khatolik' },
                  { key: 'Hindu', label: 'Hindu' },
                  { key: 'Budha', label: 'Budha' },
                  { key: 'Lainnya', label: 'Lainnya' },
                ]"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.agama.$invalid && submitted) ||
                  v$.form.agama.$pending.$response
                "
                class="p-error"
                >{{ v$.form.agama.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>No Telp 1</label>
              <InputText
                v-model="form.telepon_1"
                :class="{
                  'p-invalid': v$.form.telepon_1.$invalid && submitted,
                }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.telepon_1.$invalid && submitted) ||
                  v$.form.telepon_1.$pending.$response
                "
                class="p-error"
                >{{ v$.form.telepon_1.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>No Telp 2</label>
              <InputText
                v-model="form.telepon_2"
                class="w-full"
                input-class="w-full"
              />
            </div>
            <div class="field col-12 md:col-12">
              <label>Email</label>
              <InputText
                v-model="form.email"
                class="w-full"
                input-class="w-full"
              />
            </div>
            <div class="field col-6 md:col-6">
              <label>Pekerjaan</label>
              <InputText
                v-model="form.pekerjaan"
                :class="{
                  'p-invalid': v$.form.pekerjaan.$invalid && submitted,
                }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.pekerjaan.$invalid && submitted) ||
                  v$.form.pekerjaan.$pending.$response
                "
                class="p-error"
                >{{ v$.form.pekerjaan.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>N.P.W.P</label>
              <InputMask
                v-model="form.npwp"
                class="w-full"
                input-class="w-full"
                mask="99.999.999.9-999.999"
              />
            </div>
            <div class="field col-12 md:col-12">
              <label>Alamat</label>
              <InputText
                v-model="form.alamat"
                :class="{ 'p-invalid': v$.form.alamat.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.alamat.$invalid && submitted) ||
                  v$.form.alamat.$pending.$response
                "
                class="p-error"
                >{{ v$.form.alamat.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>Kota</label>
              <InputText
                v-model="form.kota"
                :class="{ 'p-invalid': v$.form.kota.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.kota.$invalid && submitted) ||
                  v$.form.kota.$pending.$response
                "
                class="p-error"
                >{{ v$.form.kota.required.$message }}</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>Kode Pos</label>
              <InputText
                v-model="form.kode_pos"
                class="w-full"
                input-class="w-full"
              />
            </div>
          </div>
        </div>
        <div class="hidden md:block"><Divider layout="vertical" /></div>
        <div class="col">
          <div class="field">
            <label>Foto KTP Anggota</label>
            <InputText
              type="file"
              class="w-full"
              accept="image/*"
              @change="previewKtp"
            />
            <template v-if="ktpAnggota">
              <div style="width: 100%; text-align: center; padding: 10px 2px">
                <img
                  :src="ktpAnggota"
                  class="img-fluid p-mt-3"
                  style="max-width: 150px; max-height: 150px"
                />
              </div>
            </template>
          </div>
          <div class="field">
            <label>Foto KK Anggota</label>
            <InputText
              type="file"
              class="w-full"
              accept="image/*"
              @change="previewKK"
            />
            <template v-if="kkAnggota">
              <div style="width: 100%; text-align: center; padding: 10px 2px">
                <img
                  :src="kkAnggota"
                  class="img-fluid p-mt-3"
                  style="max-width: 150px; max-height: 150px; margin: auto"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
      ktpAnggota: this.item.ktp_pemilik,
      kkAnggota: this.item.ktp_pemilik,
      fileKTP: null,
      fileKK: null,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
    previewKtp(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.ktpAnggota = e.target.result
        }
        this.fileKTP = event.target.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    previewKK(event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.kkAnggota = e.target.result
        }
        this.fileKK = event.target.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
  },
  validations() {
    return {
      form: {
        nama: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        no_ktp: {
          required: helpers.withMessage('No KTP harus diisi.', required),
        },
        no_kk: {
          required: helpers.withMessage('No KK harus diisi.', required),
        },
        tanggal_lahir: {
          required: helpers.withMessage('Tanggal Lahir harus diisi.', required),
        },
        tempat_lahir: {
          required: helpers.withMessage('Tempat Lahir harus diisi.', required),
        },
        jenis_kelamin: {
          required: helpers.withMessage('Jenis Kelamin harus diisi.', required),
        },
        agama: {
          required: helpers.withMessage('Agama harus diisi.', required),
        },
        telepon_1: {
          required: helpers.withMessage('Telepon 1 harus diisi.', required),
        },
        pekerjaan: {
          required: helpers.withMessage('Pekerjaan harus diisi.', required),
        },
        alamat: {
          required: helpers.withMessage('Alamat harus diisi.', required),
        },
        kota: {
          required: helpers.withMessage('Kota harus diisi.', required),
        },
      },
    }
  },
}
</script>

